import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MainContentComponent } from "./main-content/main-content.component";
import { RoomDetailComponent } from "./room-detail/room-detail.component";
import { AreaDetailComponent } from "./area-detail/area-detail.component";
import { UnderFloorHeating } from "./under-floor-heating/under-floor-heating.component";
import { RoomTypeComponent } from "./room-type/room-type.component";
import { ConjuctionComponent } from "./conjuction/conjuction.component";
import { HeatControlComponent } from "./heat-control/heat-control.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { ResultantComponent } from "./resultant/resultant.component";
import { ErrorPageComponent } from './error-page/error-page.component';
import { UnderfloorBoxComponent } from './underfloor-box/underfloor-box.component';
import { LoginComponent } from './login/login.component';
import { DashbordComponent } from './dashbord/dashbord.component';
import {AuthGuard} from './auth.guard';

const routes: Routes = [
  {
    path: "step-0",
    component: MainContentComponent
  },
  {
    path: "",
    redirectTo: "/step-0",
    pathMatch: "full"
  },
  {
    path: "step-1/:id",
    component: RoomDetailComponent
  }, 
  {
    path: "step-2/:id",
    component: AreaDetailComponent
  },
  {
    path: "step-3/:id",
    component: UnderfloorBoxComponent
  },
  {
    path: "step-4/:id",
    component: UnderFloorHeating
  },
  {
    path: "step-5/:id",
    component: RoomTypeComponent
  },
  {
    path: "step-6/:id",
    component: ConjuctionComponent
  },
  {
    path: "step-7/:id",
    component: HeatControlComponent
  },
  {
    path: "step-8/:id",
    component: ResultantComponent
  },
  {
    path: "contact-us",
    component: ContactUsComponent
  },
  {
    path: "underfloor-box",
    component: UnderfloorBoxComponent
  },
  {
    path: 'admin',
    component: LoginComponent,
  },
  {
    path: 'dashboard',
    component: DashbordComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "**",
    component: ErrorPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
exports: [RouterModule]
})
export class AppRoutingModule {}
