import { Component, Directive, Input } from '@angular/core';
declare var $: any;
@Component({
    selector: 'loader',
    styleUrls: ['./loader-component.css'], template: `<div class="overlay" *ngIf = "isLoader">
	<div class="loader"></div>
</div>`})
export class LoaderPageComponent {
    @Input('isLoader') isLoader: boolean;
    constructor() {
    }
    showLoader(): void {
        this.isLoader = true;
        $(".overlay.loader").addClass("active");
    }
    hideLoader(): void {
        this.isLoader = false;
        $(".overlay.loader").removeClass("active");
    }
    getStatus(): boolean {
        return this.isLoader;
    }
}