import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "../app.service";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import { HttpService } from './../http-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from './../../environments/environment';
declare var jsPDF: any;

@Component({
	selector: "app-resultant",
	templateUrl: "./resultant.component.html",
	styleUrls: ["./resultant.component.css"]
})
export class ResultantComponent implements OnInit {
	public refrenceID: any = (new Date().getTime()) + (Math.floor(Math.random() * Math.floor(10000)));

	public id: string = "";
	public toMail: string = "";
	public ccMail: string = "";
	public subject: string = "";
	public desc: string = "";
	public totalPrice: number = 0;
	public imgData: any;
	public sendAsEmailButtonClicked: boolean = false;
	public showLoader: boolean = false;
	public smartHomeSelected: boolean = false;
	public SQ610RFSelected: boolean = false;
	public JaSelected: boolean = false;
	public showMessageData: any = {};
	public titleWiseArray = [];
	public userSelectionValueArray = [];
	emailForm: FormGroup;
	submitted = false;
	KVM: any = '1';

	multiplyByTwoArray = [];
	constructor(private router: Router, private appService: AppService, private httpService: HttpService, private formBuilder: FormBuilder) {
		var finalAnsweredArray = [];
		var newArray = [];

		this.showLoader = true;
		this.userSelectionValueArray = this.appService.userSelectionValueArray;
		let obj = this.appService.showConfig();




		//filters array based on selected values
		for (let key in obj) {
			if (obj[key]['selected'] != '')
				newArray.push(obj[key]);
		}

		newArray.forEach((el) => {
			let obj;
			if (el['screen'] == "A07" && el[el['selected']] == "Trådlös styrning Smart Home") {
				this.smartHomeSelected = true;
			}
			if (el['screen'] == "A01 ROT" && el[el['selected']] == "Ja") {
				this.JaSelected = true;
			}

			if (el['screen'] == "A03" || el['screen'] == "A02")
				obj = { "screen": el['screen'], "Ans": el['selected'] };
			else
				obj = { "screen": el['screen'], "Ans": el[el['selected']] };
			finalAnsweredArray.push(obj);
		});


		this.KVM = '1';

		// extracts the value of KVM
		newArray.forEach(el => {
			if (el['screen'] == "A03")
				this.KVM = el['selected'];
		});
		var rum = '1';

		// extracts the value of rum
		newArray.forEach(el => {
			if (el['screen'] == "A02")
				rum = el['selected'];
		});

		var optionInA07 = '1';

		// extracts the value of option in A07
		newArray.forEach(el => {
			if (el['screen'] == "A07")
				optionInA07 = el[el['selected']];
		});
		//console.log('option-In-A07', optionInA07);

		//calls http service to generate product's articleId from the mapping json file.
		this.httpService.findResultArray(finalAnsweredArray, (res) => {
			//console.log("final answr", finalAnsweredArray);
			if (res['status'] == "success") {
				let url: string = 'https://floore.se/wp-json/mmw/v1/product/';

				for (let index = 0; index < res['array'].length; index++) {
					const element = res['array'][index];
					if (element['artId'] == "SQ610RF") {
						this.SQ610RFSelected = true;
						break;
					} else if (index == res['array'].length) {
						this.SQ610RFSelected = false;
					}
				}

				res['array'].forEach((arrayObj, ind) => {
					if (this.titleWiseArray.length == 0)
						this.titleWiseArray.push({
							"title": arrayObj['title'], "arr": [{
								artId: arrayObj['artId'],
								page: arrayObj['page'],
								staticDuBehover: arrayObj['staticDuBehover'],
								arithmeticManipulationForDuBehover: arrayObj['arithmeticManipulationForDuBehover'], arithmeticValueForDuBehover: arrayObj['arithmeticValueForDuBehover'], unit: arrayObj['unit'], multiplyBy: arrayObj['multiplyBy']
							}], prodDetails: []
						});
					else if (this.titleWiseArray.every(object => object['title'] != arrayObj['title']))
						this.titleWiseArray.push({
							"title": arrayObj['title'], "arr": [{
								artId: arrayObj['artId'],
								staticDuBehover: arrayObj['staticDuBehover'],
								page: arrayObj['page'],
								arithmeticManipulationForDuBehover: arrayObj['arithmeticManipulationForDuBehover'], arithmeticValueForDuBehover: arrayObj['arithmeticValueForDuBehover'], unit: arrayObj['unit'], multiplyBy: arrayObj['multiplyBy']
							}], prodDetails: []
						});
					else {
						this.titleWiseArray.forEach((Obj, ind) => {
							if (Obj['title'] == arrayObj['title'])
								Obj['arr'].push({
									artId: arrayObj['artId'],
									page: arrayObj['page'],
									staticDuBehover: arrayObj['staticDuBehover'],
									arithmeticManipulationForDuBehover: arrayObj['arithmeticManipulationForDuBehover'], arithmeticValueForDuBehover: arrayObj['arithmeticValueForDuBehover'], unit: arrayObj['unit'], multiplyBy: arrayObj['multiplyBy']
								});
						});

					}

					url += arrayObj['artId'];
					if (ind < res['array'].length - 1) {
						url += ',';
					}
				})
				//console.log("title-Wise-Array", this.titleWiseArray);

				//calls http service to generate product details from back-end.        
				this.httpService.createGetRequest(url).subscribe((response) => {
					this.showLoader = false;

					for (var i = 0; i < response.length; i++) {
						if (response[i]['variationTitle']) {
							response[i]['variationTitle'] = response[i]['variationTitle'].replace(/-/gi, ' ');
						}

						for (var j = 0; j < this.titleWiseArray.length; j++) {

							for (var k = 0; k < this.titleWiseArray[j]['arr'].length; k++) {
								if (this.titleWiseArray[j]['arr'][k]['artId'].split('*')[0] == response[i]['articleid']) {
									response[i]['multiplyBy'] = this.titleWiseArray[j]['arr'][k]['multiplyBy'];
									response[i]['arithmeticManipulationForDuBehover'] = this.titleWiseArray[j]['arr'][k]['arithmeticManipulationForDuBehover'];
									response[i]['staticDuBehover'] = this.titleWiseArray[j]['arr'][k]['staticDuBehover'];
									response[i]['arithmeticValueForDuBehover'] = this.titleWiseArray[j]['arr'][k]['arithmeticValueForDuBehover'];
									response[i]['unit'] = this.titleWiseArray[j]['arr'][k]['unit'];
									response[i]['page'] = this.titleWiseArray[j]['arr'][k]['page'];
									response[i]['duBehover'] = '';

									// if (optionInA07 == "Hoppa över") {
									//   if (this.titleWiseArray[j]['arr'][k]['page'] != "A07")
									//     this.titleWiseArray[j]['prodDetails'].push(response[i]);

									// } else
									this.titleWiseArray[j]['prodDetails'].push(response[i]);

								}
							}
						}
					}

					for (var j = 0; j < this.titleWiseArray.length; j++) {
						for (var k = 0; k < this.titleWiseArray[j]['prodDetails'].length; k++) {

							//calculating Du Behover
							if (this.titleWiseArray[j]['prodDetails'][k]['unit'] && this.titleWiseArray[j]['prodDetails'][k]['unit'].length > 0) {

								if (this.titleWiseArray[j]['prodDetails'][k]['unit'] == "KVM") {
									this.titleWiseArray[j]['prodDetails'][k]['duBehover'] = this.KVM;
								} else if (this.titleWiseArray[j]['prodDetails'][k]['unit'] == "st") {
									if (this.titleWiseArray[j]['prodDetails'][k]['staticDuBehover'] == "true")
										this.titleWiseArray[j]['prodDetails'][k]['duBehover'] = this.titleWiseArray[j]['prodDetails'][k]['arithmeticValueForDuBehover'];
									else
										this.titleWiseArray[j]['prodDetails'][k]['duBehover'] = rum;
								} else if (this.titleWiseArray[j]['prodDetails'][k]['unit'] == "ställdon") {
									this.titleWiseArray[j]['prodDetails'][k]['duBehover'] = Math.ceil(+this.KVM / (+this.titleWiseArray[j]['prodDetails'][k]['arithmeticValueForDuBehover']));
								}

							} else {
								this.titleWiseArray[j]['prodDetails'][k]['duBehover'] = 1;
								this.titleWiseArray[j]['prodDetails'][k]['unit'] = "st";
							}

							//calculating total price

							if (this.titleWiseArray[j]['prodDetails'][k]['display_price'] && this.titleWiseArray[j]['prodDetails'][k]['display_price'] != '') {
								if (this.titleWiseArray[j]['title'] == "Golvvärme") {
									//console.log(1, "title-Golvvärme-- Price-----", this.totalPrice)

									if (this.titleWiseArray[j]['prodDetails'][k]['multiplyBy'] == 'true') {
										this.totalPrice += +this.titleWiseArray[j]['prodDetails'][k]['display_price'] * (+this.KVM);
										//console.log(2, "title-multiplyBy(true)-- Price-----", this.totalPrice)

									}
									else if (this.titleWiseArray[j]['prodDetails'][k]['multiplyBy'] == 'false') {
										this.totalPrice += +this.titleWiseArray[j]['prodDetails'][k]['display_price'];
										//console.log(3, "title-multiplyBy(false)-- Price-----", this.totalPrice)

									}
									else {
										this.totalPrice += +this.titleWiseArray[j]['prodDetails'][k]['display_price'] * (+this.titleWiseArray[j]['prodDetails'][k]['multiplyBy']);
										//console.log(4, "title-else-- Price-----", this.totalPrice)

									}
								}
								else {
									if (this.titleWiseArray[j]['prodDetails'][k]['multiplyBy'] == 'bluecontent') {
										this.totalPrice += +this.titleWiseArray[j]['prodDetails'][k]['display_price'] * (+rum);
										//console.log(5, "title-multiplyBy(bluecontent)-- Price-----", this.totalPrice)

									} else if (this.titleWiseArray[j]['prodDetails'][k]['unit'] == 'ställdon') {
										this.totalPrice += (+this.titleWiseArray[j]['prodDetails'][k]['display_price'] * Math.ceil(+this.KVM / (+this.titleWiseArray[j]['prodDetails'][k]['arithmeticValueForDuBehover'])));
										//console.log(6, "title-multiplyBy(ställdon)-- Price-----", this.totalPrice)

									}
									else {
										this.totalPrice += +this.titleWiseArray[j]['prodDetails'][k]['display_price'];
										//console.log(7, "title-else-- Price-----", this.totalPrice)

									}
								}
							}
						}
					}

					//adding VAT
					console.log("Total Price", this.totalPrice)

					if (this.totalPrice > 0)
						this.totalPrice = this.formatNumber(Math.round(this.totalPrice * 1.25));
					console.log("Total Price-----", this.totalPrice)
					setTimeout(() => {

						this.createPDF();

					}, 3000);
				});

			} else if (res['status'] == 'error') {
				this.showLoader = false;

				this.showMessageData['status'] = true;
				this.showMessageData['message'] = res['array'];
				setTimeout(() => {
					this.showMessageData['status'] = false;
				}, 2000);
			}
		})
	}

	formatNumber(num) {
		return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
	}

	ngOnInit() {
		this.emailForm = this.formBuilder.group({
			toMail: ['', [Validators.required, Validators.email]],
			ccMail: ['', [Validators.email]],
			subject: ['', [Validators.required]]
		});
	}


	get f() { return this.emailForm.controls; }

	public navigate(event) {
		event.preventDefault();
		this.router.navigate(["/contact-us"]);
	}

	public onPrint() {
		window.print();
	}
	public htmlToImage(): void {
		window.scrollTo(0, 0);
		var self = this;
		html2canvas(document.getElementById('resultant')).then(function (canvas) {
			self.imgData = canvas.toDataURL();
		});
		window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);

	}
	public createPDF(): void {

		// window.scrollTo(0, 0);
		// var worker = document.getElementById('resultant');
		// var self = this;
		// // var doc = new jsPDF('portrait', 'mm', [550, 350]);

		// var myWindow = window.open("", "MsgWindow", "width=200,height=100");

		for (let index = 0; index < this.userSelectionValueArray.length; index++) {
			const element = this.userSelectionValueArray[index];
			this.resultantStaticHtml += element['value'] + '|';
		}
		this.resultantStaticHtml += `</td>
		</tr>
		<tr>
				<td height="40" style="font-size: 0;line-height: 0;"><img src="https://guide.floore.se/assets/images/blank.gif" alt="blank" /></td>
			</tr>
	</table>
	`;
		console.log(this.titleWiseArray);

		if (this.titleWiseArray.length > 0) {
			for (let index = 0; index < this.titleWiseArray.length; index++) {
				const element = this.titleWiseArray[index];
				if (index == 0)
					this.resultantStaticHtml += `	<table border="0" cellspacing="0" cellpadding="0" align="left" width="750">
<tr>
		<td style="font-size:20px; font-weight:700; line-height:1.1; color:#e73339; font-family: 'Open Sans', sans-serif;"> Resultat – vi rekommenderar: </td>
</tr>
<tr>
		<td height="22" style="font-size: 0;line-height: 0;"><img src="https://guide.floore.se/assets/images/blank.gif" alt="blank" /></td>
	</tr>
	<tr>
			<td style="font-size:14px; line-height:1.5; color:#2b2b2b; font-family: 'Open Sans', sans-serif;"> Utifrån de val du gjort i guiden rekommenderar vi dig denna golvvärmelösning. Om du har frågor som gäller för ditt specifika rum, kontakta någon av våra duktiga återförsäljare. De kan också ge dig prisuppgifter och praktiska råd. </td>
		</tr>
		<tr>
				<td height="28" style="font-size: 0;line-height: 0;"><img src="https://guide.floore.se/assets/images/blank.gif" alt="blank" /></td>
			</tr>
`;
				if (index > 0)
					this.resultantStaticHtml += `<table border="0" cellspacing="0" cellpadding="0" align="left" width="750">`;
				this.resultantStaticHtml += `<tr>
	<td style="font-size:22px; font-weight:600; line-height:1.1; color:#2b2b2b; font-family: 'Open Sans', sans-serif;">` + element['title'] + ` </td>
	</tr>
	<tr>
                                                                    <td height="28" style="font-size: 0;line-height: 0;"><img src="https://guide.floore.se/assets/images/blank.gif" alt="blank" /></td>
                                                                </tr>
                                                               `
				for (let ind = 0; ind < element['prodDetails'].length; ind++) {
					const obj = element['prodDetails'][ind];
					this.resultantStaticHtml += `<tr>
					<td>
<table border="0" cellspacing="0" cellpadding="0" width="100%">
					<tr>
						<td align="left">
							<table border="0" cellspacing="0" cellpadding="0" align="left" width="750">
									<tr>
									<td style="font-size:16px;  line-height:1.1; color:#2b2b2b; font-family: 'Open Sans', sans-serif;">`;
					this.resultantStaticHtml += obj['title'] + `</td>
						</tr>
						<tr>
							<td style="font-size: 0; line-height: 0;" height="5"><img src="https://guide.floore.se/assets/images/blank.gif" alt="" width="1" height="1"></td>
						</tr>
						<tr>
							<td style="font-size:16px;  line-height:1.1; color:#2b2b2b; font-family: 'Open Sans', sans-serif;">Art nr `+ obj['articleid'] + `</td>
						</tr>
						<tr>
							<td style="font-size: 0; line-height: 0;" height="5"><img src="https://guide.floore.se/assets/images/blank.gif" alt="" width="1" height="1"></td>
						</tr>
						<tr>
							<td style="font-size:16px;  line-height:1.1; color:#2b2b2b; font-family: 'Open Sans', sans-serif;">EAN`+ obj['ean'] + `</td>
						</tr>
						<tr>
							<td style="font-size: 0; line-height: 0;" height="5"><img src="https://guide.floore.se/assets/images/blank.gif" alt="" width="1" height="1"></td>
						</tr>
						<tr>
							<td style="font-size:16px;  line-height:1.1; color:#2b2b2b; font-family: 'Open Sans', sans-serif;">Du behöver: `+ obj['duBehover'] + ' ' + obj['unit'] + `</td>
						</tr>
						<tr>
							<td style="font-size: 0; line-height: 0;" height="5"><img src="https://guide.floore.se/assets/images/blank.gif" alt="" width="1" height="1"></td>
						</tr>
					</table>
				</td>
				<td width="300" align="right">
					<table border="0" cellspacing="0" cellpadding="0">
						<tr>
							<td><img src="`+ obj['productImage'][0] + `" alt="" width="130" height="132" /></td>
						</tr>
					</table>
				</td>
			</tr>
		</table>
	</td>
</tr>
	<tr>
	<td style="font-size:14px; line-height:1.5; color:#2b2b2b; font-family: 'Open Sans',sans-serif;"> `+ obj['description'] + `</td>
	</tr>
	<tr>
	<td height="26" style="font-size: 0; line-height: 0;"><img src="https://guide.floore.se/assets/images/blank.gif" alt="" width="1" height="1" /></td>
	</tr>
																								`;

					// 		else
					if (index == 0)
						this.resultantStaticHtml += `</table>`;
					else if (ind == element['prodDetails'].length - 1)
						this.resultantStaticHtml += `</table>`;

					if (obj['articleid'] == 'SQ610RF')
						this.resultantStaticHtml += `</table>
			<table border="0" cellspacing="0" cellpadding="0" align="left" width="750">
		<tr>
			<td style="font-size:16px;  line-height:1.1; color:#2b2b2b; font-family: 'Open Sans', sans-serif;">Alternativ:</td>
		</tr>
		<tr>
			<td height="12" style="font-size: 0; line-height: 0;"><img src="https://guide.floore.se/assets/images/blank.gif" alt="" width="1" height="1" /></td>
		 </tr>
		 <tr>
			 <td>
				 <table border="0" cellspacing="0" cellpadding="0">
					 <tr>
						<td><img src="https://guide.floore.se/assets/images/SQ605RF-framifran.jpg" alt="" width="50" height="50" /></td>
						<td width="20" style="font-size: 0;line-height: 0;"><img src="https://guide.floore.se/assets/images/blank.gif" alt="blank" height="1" width="1"/></td>
						<td style="font-size:16px; line-height:1.1; color:#2b2b2b; font-family: 'Open Sans', sans-serif;">Trådlös termostat (batteri) - vit, Art nr SQ605RF</td> 
					</tr>
				 </table>
			 </td>
		 </tr>
		 <tr>
			<td height="14" style="font-size: 0; line-height: 0;"><img src="https://guide.floore.se/assets/images/blank.gif" alt="" width="1" height="1" /></td>
		 </tr>
		 <tr>
			<td>
				<table border="0" cellspacing="0" cellpadding="0">
					<tr>
					   <td><img src="https://guide.floore.se/assets/images/SQ610.jpg" alt="" width="50" height="50" /></td>
					   <td width="20" style="font-size: 0;line-height: 0;"><img src="https://guide.floore.se/assets/images/blank.gif" alt="blank" height="1" width="1"/></td>
					   <td style="font-size:16px; line-height:1.1; color:#2b2b2b; font-family: 'Open Sans', sans-serif;">Quantum Trådlös termostat (24V) - vit, Art nr SQ610</td> 
				   </tr>
				</table>
			</td>
		</tr>
		<tr>
			<td height="14" style="font-size: 0; line-height: 0;"><img src="https://guide.floore.se/assets/images/blank.gif" alt="" width="1" height="1" /></td>
		 </tr>
		 <tr>
			<td style="text-align: center; border-bottom:1px solid #919191; height:1px;"></td>
		</tr>
		 <tr>
			<td height="14" style="font-size: 0; line-height: 0;"><img src="https://guide.floore.se/assets/images/blank.gif" alt="" width="1" height="1" /></td>
		 </tr>
	</table>
	<table border="0" cellspacing="0" cellpadding="0" align="left" width="750">	
													<tr>	
														<td>	
															<table border="0" cellspacing="0" cellpadding="0" width="100%">	
																	<tr>	
																			<td align="left">	
																				<table border="0" cellspacing="0" cellpadding="0">
																				

`;
				}
			}
		}


		this.resultantStaticHtml += ` <table border="0" cellspacing="0" cellpadding="0" width="100%">
        <tr>
            <td style="font-size:19px; font-weight:600; line-height:1; color:#2b2b2b; font-family: 'Open Sans', sans-serif;"> Rek totalpris:`+ this.totalPrice + `kr </td>
            </tr>
            <tr>
                <td height="20" style="font-size: 0; line-height: 0;"><img src="https://guide.floore.se/assets/images/blank.gif" alt="" width="1" height="1" /></td>
            </tr>
            <tr>
                <td style="font-size:17px; font-weight:400; line-height:1; color:#6c6e6e; font-family: 'Open Sans', sans-serif;"> Detta resultat har sparats med </td>
            </tr>
             <tr>
                <td height="9" style="font-size: 0; line-height: 0;"><img src="https://guide.floore.se/assets/images/blank.gif" alt="" width="1" height="1" /></td>
            </tr>
            <tr>
                <td style="font-size:17px; font-weight:400; line-height:1; color:#6c6e6e; font-family: 'Open Sans', sans-serif;"> referensnummer `+ this.refrenceID + `. </td>
                </tr>               
            </table>
        
		
			</td>
			</tr>
			</table>     
			</td>
			</tr>
			</table>     
			</td>
			</tr>
			</table>                                  
			</td>
			</tr>
			</table> 
</body>
</html>`

		// this.resultantStaticHtml += `</td></tr></table></td></tr></table></td></tr></table></body>`
		// myWindow.document.write(this.resultantStaticHtml);
		this.httpService.createPostRequest('https://guide.floore.se/php-mpdf/index.php', {
			refId: this.refrenceID, pdf: this.resultantStaticHtml.replace(/(\r\n|\r|\n)/g, "")
		}).subscribe(el => {
			//console.log(el);
		}, error => {
			//console.log(error);
			//   })
		});

		// window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
	}
	public onEmail() {
		this.sendAsEmailButtonClicked = true;
		this.htmlToImage();
	}

	public refresh() {
		window.location.href = window.location.origin;
	}

	public sendEmail() {
		this.submitted = true;

		// stop here if form is invalid
		if (this.emailForm.invalid) {
			return;
		}
		this.desc = document.getElementById("editableDiv").innerHTML;
		let url: string = 'https://guide.floore.se/php-email/';
		let emailObj: any = { to: this.toMail, cc: this.ccMail, subject: this.subject, image: this.imgData, desc: this.desc };
		this.httpService.createPostRequest(url, emailObj).subscribe((data: any) => {
			if (data['status'] == "success")
				this.sendAsEmailButtonClicked = false;

			this.showMessageData['status'] = true;
			this.showMessageData['message'] = data['msg'];
			setTimeout(() => {
				this.showMessageData['status'] = false;
			}, 2000);
		});
	}

	cancelEmail() {
		this.sendAsEmailButtonClicked = false;
		this.submitted = false;
	}
	public enteredEmail: string = "";
	public errorMessage: string = "";
	public addToCart() {
		let arr = "";
		let count = 0;
		// this.httpService.createGetRequest('assets/json/articleIdMappingWIthProductId.json').subscribe((response) => {
		// 	console.log(response);
		for (let index = 0; index < this.titleWiseArray.length; index++) {
			const element = this.titleWiseArray[index];
			for (let subIndex = 0; subIndex < element['prodDetails'].length; subIndex++) {
				let subElement = element['prodDetails'][subIndex];

				arr += '&items[' + count + '][id]=' + subElement['articleid'] + '&items[' + count + '][quantity]=' + subElement['duBehover'];
				count++;
			}

		}
		// console.log(arr);
		// return;
		if (this.enteredEmail && this.enteredEmail.length > 0) {
			let loginUrl = environment['basePath'] + 'wp-json/floore/v1/login?email=' + this.enteredEmail + '&password=password';
			this.httpService.createPostRequest(loginUrl, {}).subscribe((res) => {
				if (res && res['success'] == true) {
					let secondUrl = environment['basePath'] +  'add_to_cart.php?email=' + this.enteredEmail + arr;
					// this.httpService.createGetRequest(secondUrl).subscribe((response) => {
					var win = window.open(secondUrl, '_blank');
					// },error=>{
					// 	var win = window.open('https://floore.mbpstage.se/varukorg/', '_blank');
					// });
				}
			}, error => {
				this.errorMessage = "Ange rätt e-postadress";
				setTimeout(() => {
					this.errorMessage = "";
				}, 3000);
			});
		} else {
			this.errorMessage = "Ange rätt e-postadress";
			setTimeout(() => {
				this.errorMessage = "";
			}, 3000);
		}
		// });
	}
	private resultantStaticHtml1: string = ` `;

	private resultantStaticHtml: string = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
     <html xmlns="http://www.w3.org/1999/xhtml">
	  <head>
		  <title>Floore</title>
		  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
		  <!--[if !mso]><!-->
		  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
		  <!--<![endif]-->
		  <meta name="format-detection" content="telephone=no" />
		  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
		  <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap" rel="stylesheet">
		  <style type="text/css">
			  .ExternalClass {
				  width: 100%;
				  background-color: #ecedf1;
			  }
		  * {
			  box-sizing: border-box;
		  }
		  body {
			  font-size: 12px;
			  line-height: 1;
			  margin: 0;
			  padding: 0;
			  -webkit-font-smoothing: antialiased;
			  -webkit-text-size-adjust: 100%;
			  -ms-text-size-adjust: 100%;
			  background-color: '#fffff';
			  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
		  }
		  #bodyTable {
			  height: 100% !important;
			  margin: 0;
			  padding: 0;
			  width: 100% !important;
		  }
		  table {
			  border-collapse: collapse;
			  mso-table-lspace: 0pt;
			  mso-table-rspace: 0pt;
			  border-spacing: 0;
			  -webkit-text-size-adjust: 100%;
			  -ms-text-size-adjust: 100%;
		  }
		  img {
			  border: none;
			  outline: none;
			  text-decoration: none;
			  display: inline-block;
			  height: auto;
		  }
		  p {
			  margin: 0;
			  padding: 0;
		  }
		  a {
			  text-decoration: none;
		  }
  
		  .img-resize {
			  width: 100% !important;
			  height: auto !important;
		  }
		  .text-left {
			  text-align: left !important;
		  }
  
		  /*Media Queries*/
  
		  @media only screen and (min-width: 320px) and (max-width: 599px) {
			  body[yahoo] .fz12 {
				  font-size: 12px !important;
			  }
			  body[yahoo] .fz16 {
				  font-size: 16px !important;
			  }
		  }
  
	  </style>
  
  </head>
  <body yahoo="fix" style="background:#fff; margin: 0; padding: 0; line-height:1;-webkit-text-size-adjust: 100%;"
  id="bodyTable">
  <table width="100%" cellpadding="0" cellspacing="0" border="0">
	  <tr>
		  <td align="left">
			  <table class="wrapper" style="background: #fff;" width="750" cellspacing="0" cellpadding="0" border="0"
				  align="center">
				  
								  <!-- Section 1 -->
				  <tr>
					  <td>
				
								  
									 <table border="0" cellspacing="0" align="left" cellpadding="0" width="750">
											  <tr>
													  <td height="15" style="font-size: 0;line-height: 0;"><img src="https://guide.floore.se/assets/images/blank.gif" alt="blank" /></td>
												  </tr>
											  <tr>
													  <td><a href="#"><img  src="https://guide.floore.se/assets/images/logo.svg" alt="logo" width="110" height="70" /></a></td>                                                       
												  </tr>
												  <tr>
														  <td height="15" style="font-size: 0;line-height: 0;"><img src="https://guide.floore.se/assets/images/blank.gif" alt="blank" /></td>
													  </tr>
													  <tr>
															  <td style="font-size:14px; line-height:1.3; color:#2b2b2b; font-family: 'Open Sans', sans-serif;">
  
  

`
}