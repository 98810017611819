import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "./../app.service";
import { InsulationComponent } from "../insulation/insulation.component";
import { RoomDetailComponent } from "../room-detail/room-detail.component";
import { Subscription } from 'rxjs';

declare var $: any;

declare global {
  interface Window { dataLayer: any[]; }
}

@Component({
  selector: "  app-main-content",
  templateUrl: "./main-content.component.html",
  styleUrls: ["./main-content.component.css"]
})

export class MainContentComponent implements OnInit {

  public rootObject: any;
  private rootJSONSubscription: Subscription;

  public customerTypeChosen: number;


  constructor(private router: Router, private appService: AppService) {
    this.customerTypeChosen = 0;

    if (!this.rootObject) {
      this.rootJSONSubscription = this.appService.rootJson.subscribe((data: any) => {
        this.rootObject = data;
      });
    }
  }

  ngOnInit() {
    // get the root object with all questions and answers
    // if (this.rootObject && !this.rootObject.hasOwnProperty("que")) {
    if (this.rootObject) {

      this.rootObject = this.appService.showConfig();
    }


      if (!this.rootObject) {

        if(this.appService.data){
          this.rootObject=this.appService.data;

        }

        this.appService.rootJson.subscribe((data: any) => {
          this.rootObject = data
        });

        this.appService.resetBreadCrumb();
      }


      for (var i = 0; i < 7; i++) {
        if (this.rootObject && ((this.rootObject["A0" + '' + (i + 1)])))
          (this.rootObject["A0" + '' + (i + 1)]["selected"] = "")

      }
      if (this.rootObject && this.rootObject["A01 ROT"])
        this.rootObject["A01 ROT"]["selected"] = "";

      if (this.rootObject && this.rootObject["B02"])
        this.rootObject["B02"]["selected"] = "";

  }

  ngAfterViewInit() {
    setTimeout(() => {
      $(".select").selectbox();
      var self = this;
      $(".select").change(function ($event) {
        self.setCustomerTypeChosen($event.target.value);
      });
    }, 500);
  }

  public navigate(value) {
    event.preventDefault();
    //updates the selected value to rootobject.
    this.rootObject["A01"]["selected"] = value;

    //saves the user's choice.
    this.appService.saveUsersChoice(this.rootObject);

    if (value == 'opt1')
      this.appService.updateValueOfSelectedArray(window.location['pathname'], "DINA VAL: " + "Nybyggnation");
    else
      this.appService.updateValueOfSelectedArray(window.location['pathname'], "DINA VAL: " + "Renovering");

    let dynamicComponent;
    let routeId;
    if (this.rootObject["A01"]["selected"] == "opt1") {
      dynamicComponent = RoomDetailComponent;
      routeId = "bygga";
    } else {
      dynamicComponent = InsulationComponent;
      routeId = "renovera";
    }

    //changes router config based on current selection.
    this.router.config.forEach(el => {
      if (el["path"].indexOf("step-1") > -1) el["component"] = dynamicComponent;
    });

    //navigation based on the selection.
    this.router.navigate(["/step-1", routeId]);
  }

  public setCustomerTypeChosen(value) {
      this.customerTypeChosen = value;

      window['dataLayer'] = window['dataLayer'] || [];
      window.dataLayer.push({
        'customer_type': this.rootObject["A01"]["customer_type"][`opt${this.customerTypeChosen}`],
        'event': 'customer_type_selected'
      });
  }

  ngOnDestroy() {
    this.rootJSONSubscription.unsubscribe();
  }
}

