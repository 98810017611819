import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm:FormGroup;
  public submitted:boolean=false;
  public errorMessage:string="";
  constructor(private formBuilder:FormBuilder,private appService:AppService,private router:Router) {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  ngOnInit() {
  }
    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

  onSubmit(){

    this.submitted=true;
    if (this.f.username.value == 'admin' && this.f.password.value == "admin@123") {

      if (this.appService.isBrowser) {
        //console.log('testing');
        localStorage.setItem('isLoggedIn', "true");
      }
      this.router.navigate(["/dashboard"]);
      
    }else{
    //   localStorage.setItem('isLoggedIn', "false");
     this.errorMessage="Please enter correct username and password";
    }
  }
}
