import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AppService } from "../app.service";

@Component({
  selector: "app-room-type",
  templateUrl: "./room-type.component.html",
  styleUrls: ["./room-type.component.css"]
})
export class RoomTypeComponent implements OnInit {
  public rootObject: any = {};
  public booleanForNextStep: boolean = true;

  public id: string = "";
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppService
  ) {
    //gives the route type bygga/renoverar
    this.route.params.subscribe(params => {
      this.id = params["id"];
    });
  }

  ngOnInit() {
    //get the root object with all questions and answers
    if (!this.rootObject.hasOwnProperty("que")) {
      this.rootObject = this.appService.showConfig();
    }

    //saves the present component details in  breadcrumb.
    this.appService.saveBreadCrumb(window.location['pathname'], 'TYP AV RUM');
  }

  public navigate(value) {
    event.preventDefault();
    this.appService.updateValueOfSelectedArray(window.location['pathname'],this.rootObject["A05"][value]);

    //updates the selected value to rootobject.
    this.rootObject["A05"]["selected"] = value;

    //saves the user's choice.
    this.appService.saveUsersChoice(this.rootObject);

    //navigation based on the selection.
    this.router.navigate(["/step-6", this.id]);
  }
}
