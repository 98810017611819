import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {AppService  } from 'src/app/app.service';
declare var $;
@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
  @Input('totalNoOfRecords') totalNoOfRecords: any;
  @Input('pageIndex') pageIndex: any;
  @Output('triggerChangePage') triggerChangePage = new EventEmitter<any>();
  @Output('previousPage') previousPage = new EventEmitter<any>();
  @Output('nextPage') nextPage = new EventEmitter<any>();
  totalNoOfRecordsAvailable: number = 0;
  pageIndexCalculated: number = 0;
  public pageList: Array<any> = [];
  constructor(public appService: AppService) { }
  ngOnInit() {
  }
  ngDoCheck() {
    if (this.totalNoOfRecords != this.totalNoOfRecordsAvailable) {
      this.totalNoOfRecordsAvailable = JSON.parse(JSON.stringify(this.totalNoOfRecords));
      this.pageIndexCalculated = Math.ceil(this.totalNoOfRecordsAvailable / this.appService.noOfRecordsPerPage);
      this.setPageList();
    }
  }
  ngAfterViewInit() {
    this.totalNoOfRecordsAvailable = JSON.parse(JSON.stringify(this.totalNoOfRecords));
    this.pageIndexCalculated = Math.ceil(this.totalNoOfRecordsAvailable / this.appService.noOfRecordsPerPage);
    this.setPageList();
  }
  public setPageList() {
    this.pageList = [];
    let records = this.totalNoOfRecordsAvailable > 0 && this.totalNoOfRecordsAvailable > this.appService.noOfRecordsPerPage && this.appService.noOfRecordsPerPage > 0 ? (this.totalNoOfRecordsAvailable / this.appService.noOfRecordsPerPage) : 1;
    for (let index = 0; index < records; index++) {
      this.pageList.push((index + 1));
    }
  }
  public previous(): void {
    this.previousPage.emit();
  }
  public next(): void {
    this.nextPage.emit();
  }
  public changePage(pageNumber: number): void {
    let pageObject = { page: pageNumber };
    this.triggerChangePage.emit(pageObject);
  }
  scrollTop() {
    $("html, body").animate({scrollTop: $(".table").offset().top});
  }
}
