import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http-service';
import { PaginationPipe } from '../pipe/pagination.pipe';

@Component({
  selector: 'app-dashbord',
  templateUrl: './dashbord.component.html',
  styleUrls: ['./dashbord.component.css']
})
export class DashbordComponent implements OnInit {

  public pageIndex: number = 1;

  constructor(private httpService: HttpService) {

  }

  triggerchangePage(event: any) {
    this.pageIndex = event.page ? event.page : 1;
  }
  nextPage(): void {
    this.pageIndex = this.pageIndex + 1;
  }
  previousPage(): void {
    this.pageIndex = this.pageIndex - 1;
  }

  pdfArray: Array<any> = [];
  public data:Array<any>=[];
  searchData(results){
    console.log(results,results['value']);
    if (results['value']) 
      this.pdfArray = this.data.filter(el => (el['file'].substr(0,el['file'].length-4).toLowerCase().indexOf(results['value'].toString().toLowerCase()) > -1)||(el['date'].toLowerCase().indexOf(results['value'].toString().toLowerCase()) > -1));
      // this.triggerFilterOutputMethod({ type: "filter", params: results });
    else {
      this.pdfArray =this.data;}  
    }
  ngOnInit() {
    this.httpService.createGetRequest('https://guide.floore.se/php-mpdf/get_files.php').subscribe(el => {
      //console.log(el);
       this.pdfArray = el;
       this.data=el;
      // for (let index = 0; index < 100; index++) {
      //   this.pdfArray.push({
      //     'file': index+".html"
      //   })
      // }
    }, error => {
      //console.log(error);
    })
  }
}
