import { Pipe, PipeTransform } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Pipe({
  name: 'pagination'                                                                                                   
})
export class PaginationPipe implements PipeTransform {

  constructor(private appService: AppService) { }
  transform(data: any, pageIndex: any): any {
    //console.log(data);
      if (data == null || data == undefined || data.length == 0) {
          return data; 
      } else {
          let filteredData = [];
          let minIndex = ((pageIndex-1) * this.appService.noOfRecordsPerPage);
          let count = ((pageIndex-1) * this.appService.noOfRecordsPerPage);
          for (minIndex; minIndex < (count + this.appService.noOfRecordsPerPage); minIndex++) {
              const element = data[minIndex];
          //console.log(count,minIndex);
              if(element){
                element['index']=minIndex+1;
                filteredData.push(element);

              }
          }

          //console.log("pagination dataaaaaaaaaaa", filteredData)

          return filteredData;

          // return data;
      }
  }

}
