import { Component } from '@angular/core';
import { AppService } from './../app.service';
import { Router, NavigationStart } from '@angular/router';
import { ResultantComponent } from './../resultant/resultant.component';

@Component({
  selector: 'app-breadcrum',
  templateUrl: './breadcrum.component.html',
  styleUrls: ['./breadcrum.component.css']
})
export class BreadcrumComponent {

  public breadCrumbArray: Array<any> = [];
  showBreadCrumb: boolean = true;
  public presentPageIndex: number = 0;
  constructor(private router: Router, private appService: AppService) {
    this.appService.breadCrumbSubject.subscribe((data) => {
      this.breadCrumbArray = data;
    });
    
  }

  ngDoCheck() {
    if(window.location["pathname"]=="/admin" || window.location["pathname"]=="/dashboard"){
      this.showBreadCrumb = false;
    }
  }

  
  ngAfterViewInit() {
      

    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
      setTimeout(() => {
        if (this.router.config.some(el => ((el["path"].indexOf("step-" +
            (+this.appService.getStepNumber(window.location["pathname"]))) > -1) && el["component"] == ResultantComponent))) {
                //console.log(window.location["pathname"]=="/admin",(!(window.location["pathname"]=="/admin")))
              this.showBreadCrumb = false;
              
          } else {
            this.showBreadCrumb = true;
          }
          this.breadCrumbArray.forEach((el, index) => {
            if (el['path'] == event['url']) {
              this.presentPageIndex = index;
            }
            if (index > 0) {
              if (index > this.presentPageIndex)
                el['disabled'] = true;
              else
                el['disabled'] = false;
            } else
              el['disabled'] = false;
          })
       
        }, 0)
      }
    });
  
}

  public navigate(event) {
    if (event['disabled'])
      return false;
    else
      this.router.navigate([event['path']]);
  }
}