import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AppService } from "../app.service";
import { ResultantComponent } from "../resultant/resultant.component";

@Component({
  selector: "app-heat-control",
  templateUrl: "./heat-control.component.html",
  styleUrls: ["./heat-control.component.css"]
})
export class HeatControlComponent implements OnInit {
  public rootObject: any = {};
  public id: string = "";
  public disableButton: boolean = false;
  public openInfo: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppService
  ) {
    //gives the route type bygga/renoverar
    this.route.params.subscribe(params => {
      this.id = params["id"];
    });
  }

  ngOnInit() {
    //get the root object with all questions and answers
    if (!this.rootObject.hasOwnProperty("que")) {
      this.rootObject = this.appService.showConfig();
    }

    //saves the present component details in  breadcrumb.
    this.appService.saveBreadCrumb(window.location['pathname'], 'Styra golvvärmen');

    //enables/disables the second option based on user's previous selections.
    if (this.rootObject["A03"]['selected'] && this.rootObject["A03"]['selected'] > 24)
      this.disableButton = true;
    else
      this.disableButton = false;
  }

  public navigate(value) {
    event.preventDefault();
    //updates the selected value to rootobject.
    this.rootObject["A07"]["selected"] = value;
   
    //saves the user's choice.
    this.appService.saveUsersChoice(this.rootObject);
    if(value=='opt3')
    this.appService.updateValueOfSelectedArray(window.location['pathname'],"Ingen styrning");
    else
    this.appService.updateValueOfSelectedArray(window.location['pathname'],this.rootObject["A07"][value]);

    let dynamicComponent;
    let routePath;

    //changes router config based on current selection.
    this.router.config.forEach(el => {
      if (this.id == "renovera") {
        routePath = "step-7";
        if (el["path"].indexOf(routePath) > -1)
          el["component"] = ResultantComponent;
      } else {
        routePath =
          "step-" +
          (+this.appService.getStepNumber(window.location["pathname"]) + 1);
        if (el["path"].indexOf(routePath) > -1)
          el["component"] = ResultantComponent;
      }
    });
    //navigation based on the selection.
    this.router.navigate(["/" + routePath, this.id]);
  }
}
