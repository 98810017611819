import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AppService } from "../app.service";
import { RoomDetailComponent } from "../room-detail/room-detail.component";

@Component({
  selector: "app-insulation",
  templateUrl: "./insulation.component.html",
  styleUrls: ["./insulation.component.css"]
})
export class InsulationComponent implements OnInit {
  public rootObject: any = {};

  public id: string = "";
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppService
  ) {
    //gives the route type bygga/renoverar
    this.route.params.subscribe(params => {
      this.id = params["id"];
    });
   //saves the present component details in  breadcrumb.
    this.appService.saveBreadCrumb(window.location['pathname'], 'Isolering');
  }

  ngOnInit() {
    //get the root object with all questions and answers
    if (!this.rootObject.hasOwnProperty("que")) {
      this.rootObject = this.appService.showConfig();
    }
  }

  public navigate(value) {
    event.preventDefault();

    //updates the selected value to rootobject.
    this.rootObject["A01 ROT"]["selected"] = value;

    //saves the user's choice.
    this.appService.saveUsersChoice(this.rootObject);
    if(value=='opt1')
    this.appService.updateValueOfSelectedArray(window.location['pathname'],"Ingen extra isolering");
    else if(value=='opt2')
    this.appService.updateValueOfSelectedArray(window.location['pathname'],"Extra isolering 25 mm");
    else
    this.appService.updateValueOfSelectedArray(window.location['pathname'],"Extra isolering 50 mm");


    let dynamicComponent;
    dynamicComponent = RoomDetailComponent;

    //changes router config based on current selection.
    this.router.config.forEach(el => {
      if (el["path"].indexOf("step-2") > -1) el["component"] = dynamicComponent;
    });

    //navigation based on the selection.
    this.router.navigate(["/step-2", this.id]);
  }
}
