import { Component, Input } from '@angular/core';

@Component({
    selector: 'alert-message-model',
    template: `<div  *ngIf = "showStatus['status']"  [class.success] = "showStatus['class'] == 'success'" [class.fail] = "showStatus['class'] == 'fail'" class = "error-msg">
        <p>{{showStatus['message']}}</p>
    </div>`,
    styleUrls: ['./alert-message.css']
})
export class AlertMessageModel {

    //designed to show alert popup for error and success conditions.
    
   public modelClass: string;
   
    @Input('showStatus') showStatus: Object = {};
    constructor() {

    }
}