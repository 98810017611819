import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AppService } from "./../app.service";
import { ConjuctionComponent } from "../conjuction/conjuction.component";
import { UnderFloorHeating } from "../under-floor-heating/under-floor-heating.component";

@Component({
  selector: "app-area-detail",
  templateUrl: "./area-detail.component.html",
  styleUrls: ["./area-detail.component.css"]
})
export class AreaDetailComponent implements OnInit {
  public rootObject: any = {};
  public totalArea: string = "";

  public id: string = "";
  public showMessageData: any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppService
  ) {

    //gives the route type bygga/renoverar

    this.route.params.subscribe(params => {
      this.id = params["id"];
    });
  }

  ngOnInit() {
    //get the root object with all questions and answers
    if (!this.rootObject.hasOwnProperty("que")) {
      this.rootObject = this.appService.showConfig();
    }

    //saves the present component details in  breadcrumb.
    this.appService.saveBreadCrumb(window.location['pathname'], 'total yta');
  }

  public navigate() {
    event.preventDefault();
    if (+this.totalArea > 0) {
//console.log("clicked")
      //updates the selected value to rootobject.
      this.rootObject["A03"]["selected"] = '' + this.totalArea;
      this.appService.updateValueOfSelectedArray(window.location['pathname'],"TOTAL YTA: " + this.totalArea + " KVM");

      //saves the user's choice.
      this.appService.saveUsersChoice(this.rootObject);

      //navigation based on the selection.
      if (this.rootObject["A03"]["selected"] > 175) {
        this.router.navigate(["contact-us"]);
        return;
      } else {
        //console.log("in else")
        let routePath;

      //changes router config based on current selection.
        this.router.config.forEach(el => {
          if (this.id == "renovera") {
            routePath = "step-5";
            if (el["path"].indexOf(routePath) > -1)
              el["component"] = ConjuctionComponent;
          } else {
            routePath = "step-4";
            if (el["path"].indexOf(routePath) > -1)
              el["component"] = UnderFloorHeating;
          }
        });
        this.router.navigate(["/" + routePath, this.id]);
      }
    } else {
      this.showMessageData['status'] = true;
      this.showMessageData['message'] = 'Du behöver fylla i antal kvadratmeter för att gå vidare.';
      setTimeout(() => {
        this.showMessageData['status'] = false;
      }, 2000);
    }
  }
}
