import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../app.service';
import { AreaDetailComponent } from '../area-detail/area-detail.component';

@Component({
  selector: 'app-underfloor-box',
  templateUrl: './underfloor-box.component.html',
  styleUrls: ['./underfloor-box.component.css']
})
export class UnderfloorBoxComponent implements OnInit {
  public rootObject: any = {};

  public id: string = "";
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppService
  ) {
    //gives the route type bygga/renoverar
    this.route.params.subscribe(params => {
      this.id = params["id"];
    });
  }

  ngOnInit() {
    //get the root object with all questions and answers
    if (!this.rootObject.hasOwnProperty("que")) {
      this.rootObject = this.appService.showConfig();
    }

    //saves the present component details in  breadcrumb.
    this.appService.saveBreadCrumb(window.location['pathname'], 'Golvvärmeskåp');
  }

  public navigate(value) {
    event.preventDefault();

    //updates the selected value to rootobject.
    this.rootObject["B02"]["selected"] = value;

    //saves the user's choice.
    this.appService.saveUsersChoice(this.rootObject);

    if(value=='opt1')
    this.appService.updateValueOfSelectedArray(window.location['pathname'],"Golvvärmeskåp");
    else
    this.appService.updateValueOfSelectedArray(window.location['pathname'],"Golvvärmeskåp");

    let dynamicComponent;
    let routePath;

    this.router.config.forEach(el => {
      if (this.id == "renovera") {
        routePath = "step-4";
        if (el["path"].indexOf(routePath) > -1)
          el["component"] = AreaDetailComponent;
      } else {
        routePath = "step-3";
        if (el["path"].indexOf(routePath) > -1)
          el["component"] = AreaDetailComponent;
      }
    });
    this.router.navigate(["/" + routePath, this.id]);

    if (this.rootObject["B02"]["selected"] == "opt1") {
      dynamicComponent = AreaDetailComponent;
    } else {
      dynamicComponent = AreaDetailComponent;
    }

    //changes router config based on current selection.
    // this.router.config.forEach(el => {
    //   if (el["path"].indexOf("step-5") > -1) el["component"] = dynamicComponent;
    // });

    // //navigation based on the selection.
    // this.router.navigate(["/step-5", this.id]);
  }

}
