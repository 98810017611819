import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AppService } from "../app.service";
import { AreaDetailComponent } from "../area-detail/area-detail.component";
import { UnderfloorBoxComponent } from '../underfloor-box/underfloor-box.component';

declare var $: any;

@Component({
  selector: "app-build-house",
  templateUrl: "./room-detail.component.html",
  styleUrls: ["./room-detail.component.css"]
})
export class RoomDetailComponent implements OnInit {
  public rootObject: any = {};
  public id: string = "";
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppService
  ) {
   //get the root object with all questions and answers
   if (!this.rootObject.hasOwnProperty("que")) {
    this.rootObject = this.appService.showConfig();
  }
    //gives the route type bygga/renoverar
    this.route.params.subscribe(params => {
      this.id = params["id"];
    });
  }

  ngOnInit() {
   

    //saves the present component details in  breadcrumb.
    this.appService.saveBreadCrumb(window.location['pathname'], 'Antal rum');
  }
  ngAfterViewInit() {
    setTimeout(() => {
      $(".select").selectbox();
      var self = this;
      $(".select").change(function ($event) {
        self.navigate($event);
      });
    }, 100);
  }
  public navigate(event) {
    let value = event['target']['value'];
    event.preventDefault();

    //updates the selected value to rootobject.
    this.rootObject["A02"]["selected"] = value;

    //saves the user's choice.
    this.appService.saveUsersChoice(this.rootObject);
    this.appService.updateValueOfSelectedArray(window.location['pathname'],"Antal rum: " + value + ' RUM');

    if (this.rootObject["A02"]["selected"] == "9") {
      this.router.navigate(["contact-us"]);
      return;
    } else {
      let dynamicComponent;
      let routePath;
      dynamicComponent = UnderfloorBoxComponent;

      //changes router config based on current selection.
      this.router.config.forEach(el => {
        if (this.id == "renovera") {
          routePath = "step-3";
          if (el["path"].indexOf(routePath) > -1)
            el["component"] = dynamicComponent;
        } else {
          routePath = "step-2";
          if (el["path"].indexOf(routePath) > -1)
            el["component"] = dynamicComponent;
        }
      });

      //navigation based on the selection.
      this.router.navigate(["/" + routePath, this.id]);
    }
  }
}
