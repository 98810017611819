import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";

import { HttpClientModule } from "@angular/common/http";
import { FormsModule,ReactiveFormsModule } from "@angular/forms";
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppService } from './app.service';

import { AppComponent } from "./app.component";
import { FooterComponent } from "./footer/footer.component";
import { BreadcrumComponent } from "./breadcrum/breadcrum.component";
import { MainContentComponent } from "./main-content/main-content.component";
import { RoomDetailComponent } from "./room-detail/room-detail.component";
import { AreaDetailComponent } from "./area-detail/area-detail.component";
import { UnderFloorHeating } from "./under-floor-heating/under-floor-heating.component";
import { RoomTypeComponent } from "./room-type/room-type.component";
import { ConjuctionComponent } from "./conjuction/conjuction.component";
import { HeatControlComponent } from "./heat-control/heat-control.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { ResultantComponent } from "./resultant/resultant.component";
import { InsulationComponent } from "./insulation/insulation.component";
import { AlertMessageModel } from './alert-message/alert-message';
import { CookieService } from 'ngx-cookie-service';
import { ErrorPageComponent } from './error-page/error-page.component';
import { LoaderPageComponent } from './loader/loader-component';
import { UnderfloorBoxComponent } from './underfloor-box/underfloor-box.component';
import { LoginComponent } from './login/login.component';
import { DashbordComponent } from './dashbord/dashbord.component';
import { PaginationPipe } from './pipe/pagination.pipe';
import { PaginationComponent } from './pagination/pagination/pagination.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    BreadcrumComponent,
    MainContentComponent,AlertMessageModel,
    RoomDetailComponent,
    AreaDetailComponent,
    UnderFloorHeating,
    RoomTypeComponent,
    ConjuctionComponent,
    HeatControlComponent,
    ContactUsComponent,
    ResultantComponent,
    InsulationComponent,
    ErrorPageComponent,LoaderPageComponent, UnderfloorBoxComponent,  LoginComponent, DashbordComponent, PaginationPipe, PaginationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    // import HttpClientModule after BrowserModule.
     HttpClientModule
  ],
  providers: [CookieService],
  entryComponents: [InsulationComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
