import { Injectable, PLATFORM_ID, Inject } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Subject, throwError } from "rxjs";
import { CookieService } from 'ngx-cookie-service';
import { catchError, retry } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: "root"
})
export class AppService {

  public configUrl: string = "assets/json/queAns.json";
  public breadCrumbArray: Array<any> = [];
  public userSelectionValueArray: Array<any> = [];
  public rootJson = new Subject<any>(); // contains the root array with questions and answers
  public breadCrumbSubject = new Subject<any>(); // subject for breadcrumbs
  public data:any = {};
  public isBrowser: boolean = false;   // check browser

  public noOfRecordsPerPage:number= 50;
 

  constructor(@Inject(PLATFORM_ID) private platformId: Object,private http: HttpClient, private _cookieService: CookieService) {
    this.runGetRequest();
    if (isPlatformBrowser(this.platformId)) 
      this.isBrowser = true;
      
    if (!this._cookieService.check("breadCrumb")) {
      this.breadCrumbArray.push({ path: '/step-0', name: 'START', disabled: false });
      this.saveCookie(this.breadCrumbArray, "breadCrumb")
    }
    else
      this.breadCrumbArray = JSON.parse(this.getCookieData("breadCrumb"));
    setTimeout(() => {
      this.breadCrumbSubject.next(this.breadCrumbArray);
    }, 0);

  }

  public getConfig() {
    return this.http.get<any>(this.configUrl)
      .pipe(
        catchError(this.handleError)
      );
  }

  public runGetRequest() {
    this.getConfig().subscribe((data: any) => {
      // this.saveCookie(data, "rootJSON");
      this.data = data;
      this.rootJson.next(data);
    });
  }

  public resetBreadCrumb() {
    this.breadCrumbArray = [];
    this.userSelectionValueArray = [];
    this.breadCrumbArray.push({ path: '/step-0', name: 'START', disabled: false });
    this.saveCookie(this.breadCrumbArray, "breadCrumb");
    setTimeout(() => {
      this.breadCrumbSubject.next(this.breadCrumbArray);
    }, 0);
  }

  public showConfig() {
    // if (this.getCookieData("rootJSON"))
    //   return JSON.parse(this.getCookieData("rootJSON"));
    // //console.log("data",this.data)

    if (this.data)
    return this.data;
  }

  public saveUsersChoice(object) {
    // this.saveCookie(object, "rootJSON");
    this.data = object;
  }

  public getStepNumber(value: string): string {
    let path = value.substring(1,value.substring(1, value.length).indexOf("/") + 1);
    return path.split("-")[1];
  }

  private saveCookie(data: any, cookieName: string): void {
    this._cookieService.set(cookieName, JSON.stringify(data));
  }

  public clearCookie(): void {
    this._cookieService.deleteAll();
  }

  public updateValueOfSelectedArray(path, value): void {
    if (this.userSelectionValueArray.some(el => el['path'] == path)) {
      for (let index = 0; index < this.userSelectionValueArray.length; index++) {
        const element = this.userSelectionValueArray[index];
        if (element['path'] == path) {
          element['value'] = value;
          break;
        }

      }
    } else {
      this.userSelectionValueArray.push({ path: path, value: value });
    }
  }

  public saveBreadCrumb(path, name) {
    //saves bread crumb details
    if (this.breadCrumbArray.some(el => el['path'] == path)) {
      for (let index = 0; index < this.breadCrumbArray.length; index++) {
        const element = this.breadCrumbArray[index];
        if (element['path'] == path) {
          element['name'] = name;
          break;
        }

      }
    } else {
      if (!this.breadCrumbArray.some(el => el['name'] == name)) {
        this.breadCrumbArray.push({ path: path, name: name });
        this.saveCookie(this.breadCrumbArray, "breadCrumb");
        setTimeout(() => {
          this.breadCrumbSubject.next(this.breadCrumbArray);
        }, 0);
      }
    }
  }

  private getCookieData(cookieName: string): any {
    return cookieName=='rootJSON' ? this.data : this._cookieService.get(cookieName);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

}
