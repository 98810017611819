import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AppService } from "../app.service";
import { ConjuctionComponent } from "../conjuction/conjuction.component";
import { RoomTypeComponent } from "../room-type/room-type.component";

@Component({
  selector: "app-under-floor-heating",
  templateUrl: "./under-floor-heating.component.html",
  styleUrls: ["./under-floor-heating.component.css"]
})
export class UnderFloorHeating implements OnInit {
  public rootObject: any = {};

  public id: string = "";
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppService
  ) {
    //gives the route type bygga/renoverar
    this.route.params.subscribe(params => {
      this.id = params["id"];
    });
  }

  ngOnInit() {
    //get the root object with all questions and answers
    if (!this.rootObject.hasOwnProperty("que")) {
      this.rootObject = this.appService.showConfig();
    }

    //saves the present component details in  breadcrumb.
    this.appService.saveBreadCrumb(window.location['pathname'], 'Typ av golvvärme');
  }

  public navigate(value) {
    event.preventDefault();

    //updates the selected value to rootobject.
    this.rootObject["A04"]["selected"] = value;

    //saves the user's choice.
    this.appService.saveUsersChoice(this.rootObject);

    if(value=='opt1')
    this.appService.updateValueOfSelectedArray(window.location['pathname'],"Skivor");
    else
    this.appService.updateValueOfSelectedArray(window.location['pathname'],"Ingjutet");

    let dynamicComponent;
    if (this.rootObject["A04"]["selected"] == "opt1") {
      dynamicComponent = ConjuctionComponent;
    } else {
      dynamicComponent = RoomTypeComponent;
    }

    //changes router config based on current selection.
    this.router.config.forEach(el => {
      if (el["path"].indexOf("step-5") > -1) el["component"] = dynamicComponent;
    });

    //navigation based on the selection.
    this.router.navigate(["/step-5", this.id]);
  }
}
