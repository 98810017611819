import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AppService } from "../app.service";
import { HeatControlComponent } from "../heat-control/heat-control.component";
import { ResultantComponent } from '../resultant/resultant.component';

@Component({
  selector: "app-conjuction",
  templateUrl: "./conjuction.component.html",
  styleUrls: ["./conjuction.component.css"]
})
export class ConjuctionComponent implements OnInit {
  public rootObject: any = {};

  public id: string = "";
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppService
  ) {
    //gives the route type bygga/renoverar
    this.route.params.subscribe(params => {
      this.id = params["id"];
    });
  }

  ngOnInit() {
    //get the root object with all questions and answers
    if (!this.rootObject.hasOwnProperty("que")) {
      this.rootObject = this.appService.showConfig();
    }

    //saves the present component details in  breadcrumb.
    this.appService.saveBreadCrumb(window.location['pathname'], 'värmekälla');
  }

  public navigate(value) {
    event.preventDefault();

    //updates the selected value to rootobject.
    this.rootObject["A06"]["selected"] = value;

    //saves the user's choice.
    this.appService.saveUsersChoice(this.rootObject);
    if (value == 'opt1')
      this.appService.updateValueOfSelectedArray(window.location['pathname'], "Ej samköras element");
    else if (value == 'opt2')
      this.appService.updateValueOfSelectedArray(window.location['pathname'], "Samköras element");

    let dynamicComponent;
    let routePath;

    //console.log(this.rootObject);

    //auto-populate the A07 screen for CABIN
    if (this.rootObject && this.rootObject['B02']['selected'] == "opt1") {
      if (this.rootObject['A03']['selected'] > 0 && this.rootObject['A03']['selected'] <=24) {
        this.rootObject['A07']['selected'] = "opt2";
        this.appService.updateValueOfSelectedArray(window.location['pathname'], this.rootObject["A07"]['opt2']);
      }else  if (this.rootObject['A03']['selected'] > 24 && this.rootObject['A03']['selected'] <=175) {
        this.rootObject['A07']['selected'] = "opt1";
        this.appService.updateValueOfSelectedArray(window.location['pathname'], this.rootObject["A07"]['opt1']);
      }
      let routePath;
      this.appService.saveUsersChoice(this.rootObject);

      //changes router config based on current selection.
      this.router.config.forEach(el => {
        if (this.id == "renovera") {
          routePath = "step-7";
          if (el["path"].indexOf(routePath) > -1)
            el["component"] = ResultantComponent;
        } else {
          routePath =
            "step-" +
            (+this.appService.getStepNumber(window.location["pathname"]) + 1);
          if (el["path"].indexOf(routePath) > -1)
            el["component"] = ResultantComponent;
        }
      });
      //navigation based on the selection.
      this.router.navigate(["/" + routePath, this.id]);
      return;
      // Trådlös styrning 
    }
    //changes router config based on current selection.
    this.router.config.forEach(el => {
      if (this.id == "renovera") {
        routePath = "step-6";
        if (el["path"].indexOf(routePath) > -1)
          el["component"] = HeatControlComponent;
      } else {
        routePath =
          "step-" +
          (+this.appService.getStepNumber(window.location["pathname"]) + 1);
        if (el["path"].indexOf(routePath) > -1)
          el["component"] = HeatControlComponent;
      }
    });
    //navigation based on the selection.
    this.router.navigate(["/" + routePath, this.id]);
  }
}
