import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from "rxjs";

declare var $: any;


const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
@Injectable({
    providedIn: "root"
})
export class HttpService {

    matchArticleId: any;
    foundArticleId: boolean = false;
    constructor(private http: HttpClient) {
    }

    createGetRequest(url: string) {
        return this.http.get<any>(url)
            .pipe(
                catchError(this.handleError)
            );
    }

    findResultArray(answerArray, cb) {
        this.matchArticleId=[];
        this.foundArticleId=false;
        var jsonUrl = 'assets/json/mappingOptionWithArticleId.json';
        this.http.get(jsonUrl).subscribe((data) => {

            for (let i = 0; i < data['multipleScreenAnswers'].length; i++) {
                let matchCount = 0;
                for (let j = 0; j < data['multipleScreenAnswers'][i]['screenOptions'].length; j++) {

                    if (data['multipleScreenAnswers'][i]['screenOptions'].length == answerArray.length) {
// //console.log(1,data['multipleScreenAnswers'][i]['screenOptions'].length,data['multipleScreenAnswers'][i]['screenOptions'].length == answerArray.length);
                        if (data['multipleScreenAnswers'][i]['screenOptions'][j]['screen'] != 'A02')           //start of A02 condition
                        {
                            // //console.log(2,data['multipleScreenAnswers'][i]['screenOptions'][j]['screen']);

                            if (data['multipleScreenAnswers'][i]['screenOptions'][j]['Ans'].indexOf("m2") > 0) {
                                // //console.log(3,"indexof",data['multipleScreenAnswers'][i]['screenOptions'][j]['Ans'].indexOf("m2"));

                                if (!isNaN(answerArray[j]['Ans'])) {
                                    // //console.log(4,"isNaN",answerArray[j]['Ans']);

                                    let m2Ans = data['multipleScreenAnswers'][i]['screenOptions'][j]['Ans'].replace("m2", "");
                                    let rangeArray = m2Ans.split("-");
                                    let lowerRange = rangeArray[0];
                                    let higherRange = rangeArray[1];
                                    if (parseInt(answerArray[j]['Ans']) >= parseInt(lowerRange) && parseInt(answerArray[j]['Ans']) <= parseInt(higherRange)) {
                                        // //console.log(5,m2Ans,parseInt(answerArray[j]['Ans']));

                                        matchCount++;
                                    }
                                }
                            }
                            else {
                                if (data['multipleScreenAnswers'][i]['screenOptions'][j]['Ans'] == answerArray[j]['Ans']){
                                    matchCount++;
                                    // //console.log(7,"else if",data['multipleScreenAnswers'][i]['screenOptions'][j]['Ans'] == answerArray[j]['Ans'])
                                }
                                else {
                                }

                            }

                            if (answerArray.length == 8 && matchCount == 7) {
                                this.matchArticleId = data['multipleScreenAnswers'][i]['ArticleID'];
                                this.foundArticleId = true;
                                break;
                            }
                            if (answerArray.length == 7 && matchCount == 6) {
                                this.matchArticleId = data['multipleScreenAnswers'][i]['ArticleID'];
                                this.foundArticleId = true;
                                break;
                            }
                            if (answerArray.length == 6 && matchCount == 5) {
                                this.matchArticleId = data['multipleScreenAnswers'][i]['ArticleID'];
                                this.foundArticleId = true;
                                break;
                            }

                        }
                    }
                }

                if (this.foundArticleId) {
                    break;
                }
            }
            if (!this.foundArticleId) {
                return cb({ status: "error", array: "No article id found for this case." });
            } else
            //console.log("this.matchArticleId",this.matchArticleId)
                return cb({ status: "success", array: this.matchArticleId });

        });
    }

    createPostRequest(url: any, data: any) {

        return this.http.post<any>(url, data, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    }
}